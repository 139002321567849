<template>
  <footer id="g-footer" class="block" :style="{ backgroundColor: sexStyleType.color01 }">
    <div class="block-body">
      <img src="@/assets/images/logo_woman.png" alt="じぶんクリニック" class="logo-footer" v-if="sex == 0" />
      <img src="@/assets/images/logo_man.png" alt="じぶんクリニック" class="logo-footer" v-if="sex == 1" />
    </div>
    <div class="block-foot">
      <p id="copyright">{{ sex == 1 ? "Copyright © 2022 MEN'S JIBUN CLINIC" : "copyright© JIBUN CLINIC All Rights Reserved." }}
      </p>
    </div>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["sexStyleType", "sex"]),
  },
  methods: {
    openurl() {
      window.open("https://www.aletheia-clinic.com");
    },
  },
};
</script>
<style lang="scss" scoped>
.block-body {
  display: flex;
  justify-content: center;

  img {
    height: 55px;
    width: auto;
    margin-right: 20px;
  }
}
</style>