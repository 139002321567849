<template>
  <!-- <div class="header">
    <header>
      <div class="header_container">
        <div class="header_top">
          <img class="header_icon" src="@/assets/home/head_icon.png" alt="" />
          <img
            class="header_icon_mobile"
            src="@/assets/home/head_icon_mobile.png"
            alt=""
          />
          <div v-html="title" class="header_title"></div>
        </div>
      </div>
    </header>
  </div> -->
  <header id="g-header">
    <div class="inner has-width-limit">
      <h1>
        <img
          src="@/assets/images/logo_woman.png"
          alt="じぶんクリニック"
          class="logo-header"
          v-if="sex == '0'"
        />
        <img
          src="@/assets/images/logo_man.png"
          alt="じぶんクリニック"
          class="logo-header"
          v-if="sex == '1'"
        />
      </h1>
      <p v-html="title" :style="{ color: sexStyleType.color01 }"></p>
    </div>
  </header>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    title: {
      default: "無料カウンセリング <br class='mq' />予約フォーム",
    },
  },
  computed: {
    ...mapState(["sexStyleType", "sex"]),
  },
  methods: {
    closeMsg() {
      this.$emit("update:errMsgShow", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.inner {
  h1 {
    display: flex;
    justify-content: flex-start;
    img {
      height: 55px;
      width: auto;
      margin-right: 20px;
    }
  }
}
</style>